import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import CommonContext from '../../../context/common-context'
import Nav from '../nav'
import langCommonData from '../../../utils/common'
import './index.less'

// const logo = require('../../../asset/images/common/logo.png')
// const logoWhite = require('../../../asset/images/common/logo-white.png')

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: false,
      isIndexPage: false,
      isToggleUp: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    /* eslint-disable react/prop-types */
    const { history } = this.props
    this.pathname = history.location.pathname
    this._setDefaultHeaderWhite()
    history.listen((e) => {
      // 监听滚动，调整Header
      this.handleScroll(e.pathname)
      // _setDefaultHeaderWhite()
      this._setDefaultHeaderWhite()
      // 路由变化时，关闭移动端的遮罩和菜单Nav
      this.setState({ toggle: false })
    })
  }

  // componentWillUpdate() {
  //   // const { history } = this.props
  //   // this.pathname = history.location.pathname
  // }

  onChangeNav(state) {
    this.setState({ toggle: state })
  }

  _setDefaultHeaderWhite() {
    if (this._checkIsDefaultWhite()) {
      this.myContext.toggleHeaderWhite(true)
    }
    if (this.pathname === '/' || this.pathname === '/index') {
      this.setState({ isIndexPage: true })
    } else {
      this.setState({ isIndexPage: false })
    }
  }

  handleScroll() {
    const { history } = this.props
    this.pathname = history.location.pathname
    // console.log('object', this._checkIsDefaultWhite())
    // 根据 Nav 数据，如果 isWhiteBg = true，那么执行该方法，将底色改成白色
    if (this._checkIsDefaultWhite()) {
      this.myContext.toggleHeaderWhite(true)
      this.setState({ isToggleUp: false })
      return true
    }
    /* eslint-disabled prefer-destructuring */
    const { scrollTop } = document.documentElement
    // 默认是展示且透明底，所以在 < 300时，不做任何处理
    if (scrollTop > 300) {
      // 当滚动距离大于顶部 300 时，判断如果Header还不是白色就改变成白色
      // 且如果Header已经是白色，那么就判断滚动方向是上还是下进行Header的Toggle处理
      if (this.prevScrollTop <= scrollTop) { // 向下滚动，隐藏 header
        this.setState({ isToggleUp: true })
      } else { // 向上滚动，展开 header
        this.setState({ isToggleUp: false })
      }
      // 将底色判断移动到隐藏之后且延时200ms（隐藏动画时间），防止隐藏时同时变色，会有白色一闪的Bug效果
      if (this.myContext.headerWhite === false) {
        setTimeout(() => {
          this.myContext.toggleHeaderWhite(true)
        }, 200)
      }
    } else if (this.myContext.headerWhite === true) { // 当滚动距离小于300，且还是白底时，去掉白底、取消隐藏展示
      this.myContext.toggleHeaderWhite(false)
      this.setState({ isToggleUp: false })
    }
    this.prevScrollTop = scrollTop
    return false
  }

  /**
   * 判断 Nav 中的当前路由的背景是否是白色
   */
  _checkIsDefaultWhite() {
    const { history } = this.props
    let isWhite = false
    this.pathname = history.location.pathname
    // 根据 Nav 数据，如果 isWhite = true，那么执行该方法，将底色改成白色
    langCommonData.nav.forEach((item) => {
      this._checkRegPathname(item.link, this.pathname)
      if ((this.pathname === item.link || this._checkRegPathname(item.link, this.pathname)) && item.isWhiteBg) {
        isWhite = true
      }
    })
    return isWhite
  }

  _checkRegPathname(link, pathname) {
    if (link) {
      const i = link.indexOf(':')
      if (i !== -1) {
        let newLink = link.substr(0, i)
        newLink = newLink.replace(/\//g, '\\/')
        newLink += '[0-9]+'
        const reg = new RegExp(newLink)
        if (reg.test(pathname)) {
          return true
        }
        // var d = new RegExp("\/works\/info\/[0-9]+")
      }
    }
    return false
  }

  _setContextObj(args) {
    this.myContext = args
  }

  handleToggleNav() {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
  }

  render() {
    const {
      toggle, isIndexPage, isToggleUp
    } = this.state
    const { navData } = this.props
    let { webData } = this.props
    webData = webData || {}
    return [
      <CommonContext.Consumer key="header-top-key">
        {(cont) => {
          this._setContextObj(cont)
          const { headerWhite } = cont
          return (
            <div className={`container-fluid flex-between header-container ${headerWhite ? 'header-white' : ''} ${isIndexPage ? 'index-header' : 'other-header'} ${headerWhite && !isIndexPage ? 'shadow-header' : ''} ${isToggleUp ? 'toggle-top-header' : ''}`}>
              {/* <div className={`container-fluid flex-between header-container ${headerWhite ? 'header-white' : ''}`}> */}
              <Link to="/" className="flex-cen logo">
                {/* <img className="logo-image" src={headerWhite ? logo : logoWhite} alt="logo" /> */}
                {webData.web_name}
              </Link>
              <Nav className="hidden-sm hidden-xs" navData={navData} />
              <i className={`hidden-lg hidden-md nav-toggle icon iconfont iconlist ${headerWhite ? 'color-black' : toggle ? 'color-black' : 'color-white'}`} onClick={this.handleToggleNav.bind(this)} />
            </div>
          )
        }}
      </CommonContext.Consumer>,
      <Nav className="hidden-lg hidden-md" key="header-toggle-key" toggle={toggle} onChangeNav={this.onChangeNav.bind(this)} />
    ]
  }
}

export default withRouter(Header)
