export default {
  links: 'Links: ',
  location: 'Address: ',
  email: 'Email: ',
  qq: 'QQ: ',
  tel: 'Tel: ',
  more: 'More',
  worksTotal: 'Total {{total}}',
  membersTotal: 'Total {{total}}',
  newsTotal: 'Total {{total}}',
  memberAuthors: 'Total ({{total}})',
};
