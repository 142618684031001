import React from 'react'

export const data = {
  headerWhite: false,
  toggleHeaderWhite: () => { },
  navData: [],
};

const CommonContext = React.createContext(
  data
);

export default CommonContext
