import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { requestApi } from '@/api/config';
// import { createUuid } from '@/asset/js/util'
import './index.less';

export default class Works extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    /* eslint-disable-next-line */
    const { id } = this.props.match.params;
    requestApi('WORKS_INFO', { id })
      .then((res) => {
        this.setState({ data: res });
      })
      .catch(() => {});
  }

  render() {
    const { data } = this.state;

    let userLink = null;
    if (data.user_id) {
      userLink = (
        <Link className='num-item' to={`/member/info/${data.user_id}`}>
          <i className='icon iconfont iconyuangongqudaoAPP-wodekehu' />
          {data.username}
        </Link>
      );
    }

    console.log('data', data);
    let imagesDom = [];
    if (data.image) {
      imagesDom = data.image.map((image) => {
        return <img className='works-image' src={image} alt={data.title} />;
      });
    }

    return (
      <div className='white-container works-info-container'>
        <div className='container'>
          <div className='row works-wrapper'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <h3 className='works-title'>{data.title}</h3>
              <p className='works-num'>
                {userLink}
                <span className='num-item'>
                  <i className='icon iconfont iconeye' />
                  {data.show_num}
                </span>
                <span className='num-item'>
                  <i className='icon iconfont icondate' />
                  {data.date}
                </span>
              </p>
              <div className='cen fe-work-images-box'>{imagesDom}</div>
              <div
                className='works-overview'
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
