import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { HashRouter as Router } from 'react-router-dom'
import Routes from '../../router';
import Header from './header';
import CommonContext from '../../context/common-context';
import Footer from './footer';
import { requestApi } from '@/api/config';
import i18next from '@/lang';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seoLinkData: [],
      webData: {},
      navData: [],
      isRender: false,
    };

    this.toggleHeaderWhite = (args) => {
      this.setState({
        headerWhite: args,
      });
    };

    this.state = {
      headerWhite: false,
      toggleHeaderWhite: this.toggleHeaderWhite,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    /* eslint-disable-next-line */
    requestApi('META_INFO', {})
      .then((res) => {
        this.setState({
          seoLinkData: res.seoLink,
          webData: res.webData,
          navData: res.nav,
          // isRender: true,
        });
        this.setMetaInfo(res.webData);
      })
      .catch(() => {});
  }

  setMetaInfo(data) {
    document.title = data.web_name;

    const headDom = document.head || document.getElementsByTagName('head')[0];
    const linkDom = document.createElement('link');
    linkDom.setAttribute('rel', 'shortcut icon');
    linkDom.setAttribute('href', `${window.projectConfig.ip}${data.favicon}`);
    headDom.appendChild(linkDom);
    // <link rel="shortcut icon" href="%PUBLIC_URL%/favicon.ico" />


    const meta = document.createElement('meta');
    meta.name = 'keywords';
    meta.content = data.web_keyword;
    document.getElementsByTagName('head')[0].appendChild(meta);

    const metaDesc = document.createElement('meta');
    metaDesc.name = 'description';
    metaDesc.content = data.web_desc;
    document.getElementsByTagName('head')[0].appendChild(metaDesc);

    i18next.changeLanguage(data.language || 'zh-CN');

    setTimeout(() => {
      this.setState({ isRender: true });
    });
  }

  render() {
    const {
      headerWhite,
      toggleHeaderWhite,
      seoLinkData,
      webData,
      navData,
      isRender,
    } = this.state;
    const dom = [
      <Header key='container-key-header' webData={webData} navData={navData} />,
      <div className='content-container' key='container-key-content'>
        <Routes navs={navData} />
      </div>,
      <Footer
        className='common-footer'
        key='container-key-footer'
        seoLinkData={seoLinkData}
        webData={webData}
      />,
    ];

    return (
      <CommonContext.Provider
        value={{ headerWhite, toggleHeaderWhite, navData }}
      >
        <Router>{isRender ? dom : null}</Router>
      </CommonContext.Provider>
    );
  }
}

export default Layout;
