import random from 'lodash/random';

export function getLangData() {}

export function createRandom() {
  return parseInt(Math.random() * 1000000000, 10);
}

/**
 * 生成UUID
 */
export function createUuid() {
  return `${new Date().getTime().toString()}${random(9999, 99999)}${random(
    99,
    999
  )}`;
}

export function isPC() {
  const u = navigator.userAgent;
  const Agents = [
    'Android',
    'iPhone',
    'webOS',
    'BlackBerry',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  let flag = true;
  for (let i = 0; i < Agents.length; i += 1) {
    if (u.indexOf(Agents[i]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**
 * 得到URL参数
 * @param {*} name
 */
export function getUrlParam(name, urlSearch) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  if (!urlSearch) {
    urlSearch = window.location.search;
  }
  const r = urlSearch.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
