import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import commonLangData from '../../../utils/common'
import { createUuid } from '../../../asset/js/util'

export default class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // langData: commonLangData,
      navChildToggle: {}
    }
  }

  componentDidMount() {
    const tmp = {}
    commonLangData.nav.forEach((item, index) => {
      tmp[index] = false
    })
    this.setState({ navChildToggle: tmp })
  }

  handleCloseNav() {
    const { onChangeNav } = this.props
    if (onChangeNav) {
      onChangeNav(false)
    }
  }

  handleToggleNavChild(i) {
    // const { onToggleNavChild } = this.props
    // if (onToggleNavChild) {
    //   onToggleNavChild(data)
    // }
    const { navChildToggle } = this.state
    navChildToggle[i] = !navChildToggle[i]
    this.setState({ navChildToggle })
  }

  _renderNavList(navData) {
    // const { langData, navChildToggle } = this.state
    const list = []
    // const navData = langData.nav
    // eslint-disable-next-line no-console
    console.log('navData :>> ', navData);
    navData.forEach((item) => {
      // eslint-disable-next-line no-console
      console.log('item :>> ', item);
      const noLinkDom = (
        <NavLink exact to={item.link} className="nav-item-link" target={item.mode === 1 ? '_self' : '_blank'}>
          {item.name}
        </NavLink>
      )
      list.push(
        <li className="nav-item" key={`nav-key-${createUuid()}`}>
          {item.mode === 2
            // eslint-disable-next-line react/jsx-no-target-blank
            ? <a href={item.link} className="nav-item-link" target="_blank">{item.name}</a>
            : noLinkDom}
        </li>
      )
    })
    return list
  }

  render() {
    const { className, toggle, navData } = this.props
    const navDom = this._renderNavList(navData)
    return (
      <div className={className}>
        <div className={`nav-wrapper ${toggle ? 'nav-wrapper-toggle' : ''}`}>
          <div className="nav-list">
            <ul>{navDom}</ul>
          </div>
        </div>
        <div className={`nav-toggle-mask ${toggle ? 'nav-toggle-mask-show' : ''}`} onClick={this.handleCloseNav.bind(this)} />
      </div>
    )
  }
}

Nav.defaultProps = {
  className: '',
  toggle: false,
  onChangeNav: null,
  navData: []
  // onToggleNavChild: null
}

Nav.propTypes = {
  className: PropTypes.string,
  toggle: PropTypes.bool,
  onChangeNav: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  navData: PropTypes.array
  // onToggleNavChild: PropTypes.func
}
