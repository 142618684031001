import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../pages/home';
import Works from '../pages/works';
import WorksInfo from '../pages/worksInfo';
import News from '../pages/news';
import NewsInfo from '../pages/newsInfo';
import Member from '../pages/member';
import MemberInfo from '../pages/memberInfo';
import NotFound from '../pages/common/notFound';

const NAVS_LIST = [
  { path: '/', component: Home, code: 'index', permission: true },
  { path: '/works', component: Works, code: 'works', permission: true },
  { path: '/works/info/:id', component: WorksInfo, code: 'workInfo' },
  { path: '/news', component: News, code: 'news', permission: true },
  { path: '/news/info/:id', component: NewsInfo, code: 'newsInfo' },
  { path: '/member', component: Member, code: 'member', permission: true },
  { path: '/member/info/:id', component: MemberInfo, code: 'memberInfo' },
  { path: '*', component: NotFound, code: '404' },
];

export default class AllRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navs } = this.props;
    const codes = navs.map((nav) => nav.code);
    const list = NAVS_LIST.filter((nav) => !nav.permission || (nav.permission && codes.includes(nav.code)));
    return (
      <Switch>
        {list.map((nav, i) => <Route key={i} path={nav.path} component={nav.component} exact />)}
      </Switch>
    );
  }
}
