import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import NewsCpt from '@/components/news';
import { createUuid } from '@/asset/js/util';
import { requestApi } from '@/api/config';
import i18next from '@/lang';
import './index.less';

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalog: [],
      catalogActive: 0,
      paginateState: true,
      list: [],
      pageNo: 0,
      // pageSize: 12,
      totalPage: 0,
      totals: '',
    };
  }

  componentDidMount() {
    this.getCatalog();
    // const { pageNo, pageSize } = this.state
  }

  getCatalog() {
    requestApi('CATALOG_LIST', {})
      .then((res) => {
        const defaultId = (res[0] && res[0].id) || 0;
        this.setState({ catalog: res, catalogActive: defaultId }, () => {
          this.getData();
        });
      })
      .catch(() => {});
  }

  getData() {
    const { pageNo, catalogActive } = this.state;
    requestApi('NEWS_LIST', { page: pageNo, catalogType: catalogActive })
      .then((res) => {
        this.setState({
          list: res.data,
          pageNo: res.current_page,
          totals: res.total,
          totalPage: res.last_page,
          paginateState: true,
        });
      })
      .catch(() => {});
  }

  handlePageClick(data) {
    const pageIndex = data.selected;
    this.setState({ pageNo: pageIndex + 1 }, () => {
      this.getData();
    });
  }

  handleCatalogChange(i) {
    this.setState({ catalogActive: i, pageNo: 1, paginateState: false }, () => {
      this.getData();
    });
  }

  _renderCatalog() {
    const { catalog, catalogActive } = this.state;
    return (
      <div className='block-container'>
        <div className='container catalog-container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <ul className='catalog-box'>
                {catalog.map((item) => (
                  <li
                    className={`catalog-item ${
                      catalogActive === item.id ? 'active' : ''
                    }`}
                    onClick={this.handleCatalogChange.bind(this, item.id)}
                    key={createUuid()}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSearch() {
    const { totals } = this.state;
    return (
      <div className='block-container home-works-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='search-wrapper'>
                <p className='count'>
                  {/* className='num' */}
                  {i18next.t('common.newsTotal', { total: totals })}
                </p>
                <div className='search-content'>{/* Search */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderNews() {
    const { list, totalPage, paginateState } = this.state;
    return (
      <div className='block-container'>
        <div className='container content-wrapper'>
          <div className='row news-wrapper'>
            {list.map((item) => (
              <div
                className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
                key={createUuid()}
              >
                <NewsCpt data={item} />
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='paginate-wrapper'>
                {paginateState ? (
                  <ReactPaginate
                    onPageChange={this.handlePageClick.bind(this)}
                    // initialPage={pageNo}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    previousLabel={'<'}
                    nextLabel='>'
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const cataloghDom = this._renderCatalog();
    const searchDom = this._renderSearch();
    const listDom = this._renderNews();
    return (
      <div className='white-container news-list-container'>
        {cataloghDom}
        {searchDom}
        {listDom}
      </div>
    );
  }
}
