import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import commonLangData from '@/utils/common'
import i18next from '@/lang';
import './index.less'

export default class More extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    const { name, url } = this.props
    return (
      <div className="more-box">
        <p className="more-name">{name}</p>
        <Link className="more-link" to={url}>
          {i18next.t('common.more')}
          <i className={commonLangData.moreIcon} />
        </Link>
      </div>
    )
  }
}

More.defaultProps = {
  name: '',
  url: ''
}

More.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string
}
