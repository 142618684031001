import React, { Component } from 'react';
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper/dist/js/swiper.min';
import More from '@/components/more';
import Works from '@/components/works';
import News from '@/components/news';
import Member from '@/components/member';
import commonLangData from '@/utils/common';
import { createUuid } from '@/asset/js/util';
import { requestApi } from '@/api/config';
import CommonContext from '@/context/common-context';
import './index.less';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        works: [],
        user: [],
        news: [],
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    requestApi('HOME_DATA', {})
      .then((res) => {
        this.setState({ data: res }, () => {
          /* eslint-disable-next-line */
          const mySwiper = new Swiper('.swiper-container', {
            loop: true, //循环
            delay: 3000,
            autoplay: {
              //滑动后继续播放（不写官方默认暂停）
              disableOnInteraction: false,
            },
            pagination: {
              //分页器
              el: '.swiper-pagination',
              clickable: true,
            },
            // slidesPerView: 'auto',
            // mousewheel: true,
          });
        });
      })
      .catch(() => {});
  }

  _renderWorks(navData) {
    const tmp = navData.filter((item) => item.code === 'works');
    const routeInfo = tmp && tmp.length ? tmp[0] : {};

    const { data } = this.state;
    const list = (data && data.works) || [];
    if (!list.length) {
      return null;
    }
    return (
      <div className='block-container home-works-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <More name={routeInfo.name} url={routeInfo.link} />
            </div>
          </div>
          <div className='row works-wrapper'>
            {list.map((item, i) => (
              <div
                key={createUuid() + i}
                className='col-xs-6 col-sm-6 col-md-3 col-lg-3'
              >
                <Works data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  _renderNews(navData) {
    const tmp = navData.filter((item) => item.code === 'news');
    const routeInfo = tmp && tmp.length ? tmp[0] : {};

    const { data } = this.state;
    const list = (data && data.news) || [];
    if (!list.length) {
      return null;
    }
    return (
      <div className='block-container home-news-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <More name={routeInfo.name} url={routeInfo.link} />
            </div>
          </div>
          <div className='row news-wrapper'>
            {list.map((item) => (
              <div
                className='col-xs-12 col-sm-12 col-md-6 col-lg-6'
                key={createUuid()}
              >
                <News data={item} key={createUuid()} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  _renderMember(navData) {
    const tmp = navData.filter((item) => item.code === 'member');
    const routeInfo = tmp && tmp.length ? tmp[0] : {};

    const { data } = this.state;
    const list = (data && data.user) || [];
    if (!list.length) {
      return null;
    }
    return (
      <div
        key='home-container'
        className='block-container home-member-container'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <More name={routeInfo.name} url={routeInfo.link} />
            </div>
          </div>
          <div className='row member-wrapper'>
            {list.map((item) => (
              <div
                className='col-xs-4 col-sm-4 col-md-2 col-lg-2'
                key={createUuid()}
              >
                <Member data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    // const { page1 } = langData
    const { data } = this.state;
    const banner = data && data.banner && data.banner.length ? data.banner : [];
    return (
      <CommonContext.Consumer key='home-key'>
        {(cont) => {
          const { navData } = cont;
          const doms = {};
          doms.works = this._renderWorks(navData);
          doms.news = this._renderNews(navData);
          doms.member = this._renderMember(navData);

          // 按菜单按顺序显示
          navData.forEach((nav) => {
            nav.component = doms[nav.code];
          });

          return (
            <div key='home-container23' className='home-container'>
              {/* Banner */}
              <div className='block-container swiper-container swiper-container-page'>
                <div className='swiper-wrapper'>
                  {banner.map((item, i) => (
                    <a
                      href={item.link}
                      key={`swiper${createUuid()}-${i}`}
                      className='swiper-slide swiper-slide-3'
                      target='__blank'
                    >
                      <img
                        src={item.content}
                        srcSet={`${item.content} 2x, ${item.content} 3x`}
                        alt='bannerImage'
                      />
                    </a>
                  ))}
                </div>
                <div className='swiper-pagination' />
              </div>
              {navData.map((nav) => nav.component || null)}
            </div>
          );
        }}
      </CommonContext.Consumer>
    );
  }
}
