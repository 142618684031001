import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import './index.less'

export default class News extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    const { data } = this.props
    return (
      <Link to={`/news/info/${data.id}`} className="news-item">
        {/* <Link to="news/info/124" className="news-item"> */}
        <p className='news-item-left'>
          <i className="catalog-name">{data.catalog_name}</i>
          <span className="news-name">{data.name}</span>
        </p>
        <span className="news-date">{dayjs(data.created_at).format('YYYY-MM-DD')}</span>
      </Link>
    )
  }
}

News.defaultProps = {
  data: {
  }
}

News.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
}
