import axios from 'axios'
import qs from 'qs'

export const ERROR_OK = 200
// export const HOST = 'http://test.supergan.cn:8888'
// export const HOST = 'http://ad.calligraphy.com'
// export const HOST = 'http://ad.hbctshy.com'
// export const HOST = 'http://ad.whmaque.com'
// export const HOST = 'http://ad.hbctshy.com'
export const HOST = window.projectConfig.ip;
export const api = {
  HOME_DATA: '/api/getHomeData',
  CATALOG_LIST: '/api/getCatalog',
  NEWS_LIST: '/api/getNewsList',
  NEWS_INFO: '/api/getNewsInfo',
  WORKS_LIST: '/api/getWorksList',
  WORKS_INFO: '/api/getWorksInfo',
  MEMBER_LIST: '/api/getMemberList',
  MEMBER_INFO: '/api/getMemberInfo',
  META_INFO: '/api/getBaseInfo'
}

export function getApiByKey(key) {
  return `${HOST}${api[key]}`
}

export function requestApi(key, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(getApiByKey(key), qs.stringify(params))
      .then(
        (res) => {
          if (res.data.code === ERROR_OK) {
            resolve(res.data.data)
          } else {
            reject(res)
          }
        },
        (err) => {
          reject(err)
        }
      )
      .catch((err) => {
        reject(err)
      })
  })
}
