import React, { Component } from 'react'
import { requestApi } from '@/api/config'
import './index.less'

export default class NewsInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {}
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    /* eslint-disable-next-line */
    const { id } = this.props.match.params
    requestApi('NEWS_INFO', { id }).then((res) => {
      this.setState({ data: res })
    }).catch(() => { })
  }

  render() {
    const { data } = this.state
    return (
      <div className="white-container news-info-container">
        <div className="container">
          <div className="row news-wrapper">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h3 className="news-title">{data.name}</h3>
              <p className="news-num">
                <span className="num-item">
                  <i className="icon iconfont icondate" />
                  {data.date}
                </span>
              </p>
              <div className="news-overview" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
