import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './index.less'

export default class Member extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    const { data } = this.props
    return (
      <Link to={`/member/info/${data.id}`} className="member-item">
        <div className="member-image">
          {/* <img className="image" src={} alt="img" /> */}
          <div className="member-image-in" style={{ backgroundImage: `url(${data.avatar})` }} />
        </div>
        <div className="member-info">
          <p className="member-name">{data.real_name}</p>
        </div>
      </Link>
    )
  }
}

Member.defaultProps = {
  data: {
  }
}

Member.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
}
