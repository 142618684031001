import React, { Component } from 'react';
import './index.less';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='white-container'>
        <div className='block-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                <div style={{ height: 'calc(100vh - 426px)' }}>
                  <h1 style={{ fontSize: '100px' }}>404</h1>
                  <p>This page was not found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
