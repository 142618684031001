import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom'
import { createUuid } from '@/asset/js/util';
import i18next from '@/lang';

import './index.less';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.footerRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      const { onRefFooterDom } = this.props;
      if (onRefFooterDom) {
        onRefFooterDom(this.footerRef);
      }
    }, 20);
  }

  render() {
    const { className, seoLinkData, webData } = this.props;

    const friendsNode = (
      <div className='container footer-wrapper'>
        <div className='friend-links'>
          <span className='link-tit'>{i18next.t('common.links')}</span>
          {seoLinkData.map((item) => (
            <a href={item.link} target='__blank' key={createUuid()}>
              {item.name}
            </a>
          ))}
        </div>
      </div>
    );
    return (
      <div
        className={`container-fluid flex-cen footer-container ${className}`}
        ref={this.footerRef}
      >
        {seoLinkData.length ? friendsNode : null}
        <div className='container footer-wrapper'>
          <div className='footer-info'>
            {webData.web_address ? (
              <p className=''>
                {i18next.t('common.location')}
                {webData.web_address}
              </p>
            ) : null}

            {webData.web_email ? (
              <p className=''>
                {i18next.t('common.email')}
                {webData.web_email}
              </p>
            ) : null}

            {webData.web_qq ? (
              <p className=''>
                {i18next.t('common.qq')}
                {webData.web_qq}
              </p>
            ) : null}

            {webData.web_tel ? (
              <p className=''>
                {i18next.t('common.tel')}
                {webData.web_tel}
              </p>
            ) : null}
            {webData.web_beian ? (
              <p className='copyright'>{webData.web_beian}</p>
            ) : null}
          </div>
          <div className='hidden-xs hidden-sm footer-nav' />
        </div>
      </div>
    );
  }
}

Footer.defaultProps = {
  className: '',
  onRefFooterDom: null,
  seoLinkData: [],
  webData: {},
};

Footer.propTypes = {
  className: PropTypes.string,
  onRefFooterDom: PropTypes.func,
  seoLinkData: PropTypes.arrayOf(PropTypes.any),
  webData: PropTypes.objectOf(PropTypes.any),
};
