import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './index.less'

export default class Works extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    const { data } = this.props
    return (
      <Link to={`/works/info/${data.code}`} className="works-item">
        <div className="works-image">
          {/* <img className="image" src={} alt="img" /> */}
          <div className="works-image-in" style={{ backgroundImage: `url(${data.image})` }} />
        </div>
        <div className="works-info">
          <p className="works-name">{data.title}</p>
        </div>
      </Link>
    )
  }
}

Works.defaultProps = {
  data: {}
}

Works.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
}
