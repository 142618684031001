import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import WorksCpt from '@/components/works';
import { createUuid } from '@/asset/js/util';
import { requestApi } from '@/api/config';
import i18next from '@/lang';
import './index.less';

export default class WorksInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pageNo: 1,
      totalPage: 10,
      totals: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { pageNo } = this.state;
    requestApi('WORKS_LIST', { page: pageNo })
      .then((res) => {
        this.setState({
          list: res.data,
          pageNo: res.current_page,
          totals: res.total,
          totalPage: res.last_page,
        });
      })
      .catch(() => {});
  }

  handlePageClick(data) {
    const pageIndex = data.selected;
    this.setState({ pageNo: pageIndex + 1 }, () => {
      this.getData();
    });
  }

  _renderSearch() {
    const { totals } = this.state;
    return (
      <div className='block-container home-works-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='search-wrapper'>
                <p className='count'>
                  {/* className='num' */}
                  {i18next.t('common.worksTotal', { total: totals })}
                </p>
                <div className='search-content'>{/* Search */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderWorks() {
    const { list, totalPage } = this.state;
    return (
      <div className='block-container home-works-container'>
        <div className='container'>
          <div className='row works-wrapper'>
            {list.map((item) => (
              <div
                className='col-xs-6 col-sm-6 col-md-3 col-lg-3'
                key={createUuid()}
              >
                <WorksCpt data={item} />
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='paginate-wrapper'>
                <ReactPaginate
                  onPageChange={this.handlePageClick.bind(this)}
                  pageCount={totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={'<'}
                  nextLabel='>'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const searchDom = this._renderSearch();
    const listDom = this._renderWorks();
    return (
      <div className='white-container works-list-container'>
        {searchDom}
        {listDom}
      </div>
    );
  }
}
