import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import WorksCpt from '@/components/works';
import { requestApi } from '@/api/config';
import { createUuid } from '@/asset/js/util';
import i18next from '@/lang';
import './index.less';

export default class MemberInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      list: [],
      pageNo: 1,
      // pageSize: 12,
      totalPage: 10,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    /* eslint-disable-next-line */
    const { id } = this.props.match.params;
    requestApi('MEMBER_INFO', { id })
      .then((res) => {
        this.setState({
          data: res.user,
          list: res.works.data,
          pageNo: res.works.current_page,
          totals: res.works.total,
          totalPage: res.works.last_page,
        });
      })
      .catch(() => {});
  }

  handlePageClick(data) {
    const pageIndex = data.selected;
    this.setState({ pageNo: pageIndex + 1 }, () => {
      this.getData();
    });
  }

  render() {
    const { data, list, totalPage, totals, pageNo } = this.state;
    // eslint-disable-next-line no-console
    console.log(pageNo);
    return (
      <div className='white-container member-info-container'>
        <div className='container'>
          <div className='row member-content'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='top'>
                <div className='avatar'>
                  <div
                    className='avatar-image'
                    style={{ backgroundImage: `url(${data.avatar})` }}
                  />
                  <p className='username'>{data.real_name}</p>
                </div>
                <div className='member-info'>
                  <p className='introduction'>{data.introduction}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row works-wrapper'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <p className='works-list-tit'>
                {i18next.t('common.memberAuthors', { total: totals })}
              </p>
            </div>
          </div>
          <div className='row works-wrapper'>
            {list.map((item) => (
              <div
                className='col-xs-6 col-sm-6 col-md-3 col-lg-3'
                key={createUuid()}
              >
                <WorksCpt data={item} />
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='paginate-wrapper'>
                <ReactPaginate
                  onPageChange={this.handlePageClick.bind(this)}
                  pageCount={totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={'<'}
                  nextLabel='>'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
