import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import zhCn from './zh-cn';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    'zh_CN': { translation: zhCn },
  },
  fallbackLng: 'zh-CN',
  preload: ['en', 'zh_CN'],
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  detection: {
    caches: ['sessionStorage'],
  },
  // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'no'],
});

export default i18n;
