export default {
  links: '友情链接：',
  location: '地址：',
  email: '邮箱：',
  qq: 'QQ：',
  tel: '电话：',
  more: '更多',
  worksTotal: '共 {{total}}',
  membersTotal: '共 {{total}}',
  newsTotal: '共 {{total}} ',
  memberAuthors: '共（{{total}}）',
};
