const WORKS = {
  key: 'works', name: '作品展示', link: '/works', isWhiteBg: true
}
const MEMBER = {
  key: 'member', name: '协会成员', link: '/member', isWhiteBg: true
}
const NEWS = {
  key: 'news', name: '新闻资讯', link: '/news', isWhiteBg: true
}

const data = {
  nav: [
    { key: 'index', name: '首页', link: '/' },
    WORKS,
    MEMBER,
    NEWS,
    { link: '/works/info/:id', isWhiteBg: true, isShow: false },
    { link: '/news/info/:id', isWhiteBg: true, isShow: false },
    { link: '/member/info/:id', isWhiteBg: true, isShow: false },
    { link: '*', isWhiteBg: true, isShow: false }
  ],
  navName: {
    works: WORKS,
    member: MEMBER,
    news: NEWS
  },
  more: '更多',
  moreIcon: 'icon iconfont iconright',
  navOverview: '产品咨询：400-8473-666    |    周一到周日 9:00-18:00',
  footer: {
    tel: '400-8399-222',
    email: '1234567890@gmail.com',
    address: '武汉xx路xx街153号',
    copyright: 'Copyright © 2019 武汉书法协会 版权所有 备案号：蜀ICP备19018073号-3'
  }
}
export default data;
